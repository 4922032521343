/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import data from "../data/atelier.json";

const Atelier = ({isBg}) => {
  const { authorv3 } = data;
  return (
    <section id="qui-suis-je" className={`section-padding pt-3 authorv3 ${isBg === "yes" ? "bg-one": "" }`}>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
            <div className="section-title-center text-center">
              <h2 className="display-6">{authorv3.title}</h2>
              <div className="section-divider divider-traingle"></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div
              className="col-lg-4"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="200"
          >
            <div className="author-image">
              <img className="img-fluid" src={authorv3.image} alt=""/>
            </div>
          </div>
          <div
              className="col-lg-8"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="200"
          >
            <div className="authorv3__content">
              <h3 className="display-6 mb-0">{authorv3.subtitle}</h3>
              <p className="m-30px-b text-muted fs-5">{authorv3.text}</p>
              <p className="m-30px-b">{authorv3.description}</p>
              <ul className="social-icon mt-0 mb-0">
                {authorv3.social?.map((data, i) => (
                    <li key={i}>
                      {data.link === "" ? (
                          ""
                      ) : (
                          <a href={data.link}>
                            <img
                                className="img-fluid"
                                src={data.icon}
                                alt="icon"
                                width="25"
                                height="25"
                            />
                          </a>
                      )}
                    </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Atelier;

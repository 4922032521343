import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import "./assets/css/margins-paddings.css";
import MainPage from "./components/pages/main-page";
import AOS from "aos";
import {useEffect} from "react";
import "./assets/css/aos.css";
import MentionsLegales from "./components/pages/mentions-legales";
import footerData from "./data/footer.json";
import Blog001 from "./components/pages/blogs/001";
import Blog003 from "./components/pages/blogs/003";
import Blog002 from "./components/pages/blogs/002";

function ScrollToTop() {
    const {pathname} = useLocation();

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    if (location.hash) {
        let elem = document.getElementById(location.hash.slice(1))
        if (elem) {
            let navs = document.getElementsByClassName("nav-link active");
            let menu = document.getElementById("navbarSupportedContent")
            let menu_toggler = document.getElementsByClassName("navbar-toggler").item(0)
            for (let i = 0; i < navs.length; i++) {
              navs[i].classList.remove("active");
            }

            elem.scrollIntoView({behavior: "smooth"})
            let nav = document.getElementById(location.state.id)
            nav.classList.add("active")
            menu.classList.remove("show")
            menu_toggler.classList.add("collapsed")
            menu_toggler.click()
        }
    } else {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"})
    }
    return null;
}

function App() {
    const {footer} = footerData;
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <div className="section-wrapper">
            <div id="preLoader"></div>
            <BrowserRouter>
                <ScrollToTop/>
                <Routes>
                    <Route path="/" element={<MainPage footer={footer}/>}/>
                    <Route
                        path="l-osteopathie-pour-la-femme-enceinte"
                        element={<Blog001 footer={footer}/>}
                    />
                    <Route
                        path="l-osteopathie-pour-l-adulte"
                        element={<Blog002 footer={footer}/>}
                    />
                    <Route
                        path="l-osteopathie-pour-le-nourrisson"
                        element={<Blog003 footer={footer}/>}
                    />
                    <Route
                        path="mentions-legales"
                        element={<MentionsLegales footer={footer}/>}
                    />
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;

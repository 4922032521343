/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const MentionsLegalesApp = () => {
    return (
        <div id="mentions-legales" className="section-padding single-blog">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <article>
                            <div className="single-blog__para">
                                <strong>Information générales</strong>
                                <p style={{textAlign: "justify"}}>
                                    Le présent site internet est édité par Léa Baubin EI,
                                    ostéopathe exerçante sous le régime de micro-entreprise, dont le siège social est
                                    situé au 473 Route du Bord de Mer 06270 Villeneuve-Loubet.

                                </p>
                                <strong>Responsable de publication</strong>
                                <p style={{textAlign: "justify"}}>
                                    Le responsable de publication est Thomas Mahé, agissant en qualité de responsable de
                                    publication bénévole.
                                </p>

                                <strong>Hébergement</strong>
                                <p style={{textAlign: "justify"}}>
                                    Le site internet est hébergé par Obambu société SARL au capital de 15.000,00€,
                                    immatriculée au Registre du Commerce et des Sociétés de Paris sous le
                                    numéro <strong>Paris B 803 988 757</strong>,
                                    dont le siège social est situé au 10 rue de Penthièvre 75008 Paris - France («
                                    Obambu »).
                                </p>

                                <strong>Propriété intellectuelle</strong>
                                <p style={{textAlign: "justify"}}>
                                    Le contenu du site internet (textes, images, vidéos, etc.) est protégé par le droit
                                    de la propriété intellectuelle.
                                    Toute reproduction ou représentation, en tout ou partie, sans autorisation préalable
                                    et écrite de Léa Baubin EI, est strictement interdite et constitue un délit de
                                    contrefaçon puni par la loi.
                                </p>

                                <strong>Données personnelles</strong>
                                <p style={{textAlign: "justify"}}>
                                    Les données personnelles collectées sur le site internet sont traitées conformément
                                    à la réglementation en vigueur en matière de protection des données personnelles.
                                    Pour en savoir plus, veuillez consulter notre politique de confidentialité.
                                </p>

                                <strong>Cookies</strong>
                                <p style={{textAlign: "justify"}}>
                                    Le site utilise des traceurs (cookies) placés sous le contrôle de tiers, qui sont
                                    soumis à votre
                                    consentement préalable. Ces traceurs sont utilisés pour mesurer l’audience du site.
                                </p>
                                <p>
                                    Vous pouvez vous opposer à l'utilisation de ces traceurs en paramétrant votre
                                    navigateur ou en vous rapprochant du tiers concerné.
                                </p>
                                <p>
                                    L'utilisation des traceurs est régie par l'article 32 II de la loi n° 78-17 du 6
                                    janvier 1978, transposant l'article 5.3 de la directive 2002/58/CE du parlement
                                    européen et du conseil du 12 juillet 2002 modifiée par la directive 2009/136/CE.
                                </p>
                                <p>
                                    Pour en savoir plus sur les cookies et traceurs, nous vous invitons à consulter le
                                    site de la CNIL : <a rel={"noreferrer"} target={"_blank"} href={"https://cnil.fr"}>cnil.fr</a>.
                                </p>

                                <strong>Responsabilité</strong>
                                <p style={{textAlign: "justify"}}>
                                    Les informations présentes sur le site internet sont fournies à titre indicatif et
                                    ne sauraient engager la responsabilité de Léa Baubin.
                                    Léa Baubin ne saurait être tenue responsable des dommages directs ou indirects qui
                                    pourraient résulter de l'accès ou de l'utilisation du site internet,
                                    y compris des dommages ou virus qui pourraient infecter votre équipement
                                    informatique ou tout autre bien.
                                </p>

                                <strong>Loi applicable et juridiction compétente</strong>
                                <p style={{textAlign: "justify"}}>
                                    Les présentes mentions légales sont soumises à la loi française.
                                    En cas de litige, les tribunaux français seront seuls compétents.
                                </p>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MentionsLegalesApp;
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import data from "../../data/footer.json";
import { Link } from "react-scroll";
import { siteLogo, socialLink } from "../../global";

const Footer = ({ menu, isBg }) => {
  const { footer } = data;

  return (
    <>
      {/*<CtaV3 />*/}
      <footer
        className={`footer overflow-hidden ${isBg === "yes" ? "bg-one" : ""}`}
      >
        <div className="container">
          <div className="footer__top m-50px-t m-50px-b">
            <div className="row">
              <div className="col-lg-3 d-flex justify-content-center align-items-center mb-3 mb-lg-0 justify-content-lg-start">
                <a href="/">
                  {/* <!-- <h1 className="m-0">WONTED</h1> --> */}
                  <img src={siteLogo.logo} alt={siteLogo.alt} />
                </a>
              </div>
              <div className="col-lg-6 d-flex justify-content-center align-items-center mb-3 mb-lg-0">
                <ul className="footer__menu">
                  {menu?.map((data, i) => (
                    <li key={i}>
                      <Link
                        activeClass="active"
                        className="benefits"
                        to={`${data.link}`}
                        spy={true}
                        smooth={true}
                        duration={500}
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        offset={-60}
                      >
                        {data.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-3 d-flex justify-content-center align-items-center mb-3 mb-lg-0 justify-content-lg-end">
                <ul className="social-icon mt-3">
                {socialLink.instagram === "" ? (
                  ""
                ) : (
                  <li>
                    <a target={"_blank"} rel={"noreferrer"} href={socialLink.instagram}>
                      <img
                        className="img-fluid"
                        src="assets/icons/instagram.png"
                        alt="icon"
                        width="25"
                        height="25"
                      />
                    </a>
                  </li>
                )}

                {socialLink.linkedin === "" ? (
                  ""
                ) : (
                  <li>
                    <a target={"_blank"} rel={"noreferrer"} href={socialLink.linkedin}>
                      <img
                        className="img-fluid"
                        src="assets/icons/linkedin.svg"
                        alt="icon"
                        width="25"
                        height="25"
                      />
                    </a>
                  </li>
                )}

                {socialLink.doctolib === "" ? (
                  ""
                ) : (
                  <li>
                    <a target={"_blank"} rel={"noreferrer"} href={socialLink.doctolib}>
                      <img
                        className="img-fluid"
                        src="assets/icons/doctolib.png"
                        alt="icon"
                        width="75"
                        height="25"
                      />
                    </a>
                  </li>
                )}
              </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="footer__copyright m-20px-t m-20px-b">
            <div className="row">
              <div className="col-12">
                <p className="m-0 text-center">
                  &copy; {footer.copyright}
                  <a href="mentions-legales">{footer.name}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
    // <!-- ========== Footer section End ========== -->
  );
};

export default Footer;


import React from "react";

import Header from "../global/header";
import Footer from "../global/footer";
import Breadcrumbs from "../breadcrumbs";
import MentionsLegalesApp from "../mentions-legales-app";

const MentionsLegales = ({ header, footer}) => {
  const { menuv2 } = footer;

  return (
    <>
      <Header  />
      <Breadcrumbs isBg="yes" title="Mentions Légales" />
      <MentionsLegalesApp isBg=""/>
      <Footer isBg="yes" menu={menuv2} />
    </>
  );
};

export default MentionsLegales;

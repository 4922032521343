export const siteLogo = {
    logo: "assets/images/wonted-logo.png",  // Add your site logo here
    alt: "Léa Baubin - Ostéopathe D.O Villeneuve Loubet"  // Add your site name here
};
export const socialLink = {
    facebook: "https://www.facebook.com",
    twitter: "https://www.twitter.com",
    linkedin: "https://www.linkedin.com/in/l%C3%A9a-baubin-43a5b51aa",
    youtube: "https://www.youtube.com",
    whatsapp: "https://www.whatsapp.com",
    instagram: "https://www.instagram.com/leabaubinosteo/",
    doctolib: "https://www.doctolib.fr/osteopathe/villeneuve-loubet/lea-baubin"
};
export const mailchimpLink = {
    link: (process.env.REACT_APP_MAILCHAIMP) // Add your mailchimp list link here
};
export const contactFormAction = {
    link: "https://formspree.io/f/xqkodygr"  // Add for link here from https://formspree.io
}

export const contactMap = {
    link: "https://maps.google.com/maps?q=473%20Route%20du%20Bord%20de%20Mer,%20Villeneuve-Loubet&t=&z=13&ie=UTF8&iwloc=&output=embed" // Add google map link from here: https://www.embedgooglemap.net/
}

import React from "react";


const GoogleIframe = ({isBg}) => {

  return (
    // <!-- ========== CTA v5 section start ========== -->
    <section className={`section-padding ctav5  ${isBg === "yes" ? "bg-one": "" }`}>
        <div className="row">
          <div className="ctav5__content">
            <div className="googlemap">
        <iframe
          className="google-iframe"
          title="Map"
          src="https://maps.google.com/maps?q=L%C3%A9a%20BAUBIN%20Ost%C3%A9opathe&t=&z=13&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          style={{border:0}}
          allowFullScreen
          aria-hidden="false"
          tabIndex="0"
          />
          </div>
          </div>
        </div>
    </section>
    // <!-- ========== CTA v5 section end ========== -->
  );
};

export default GoogleIframe;

import React from "react";
import LeCabinet from "../le-cabinet";
import PourQui from "../pour-qui";
import Contact from "../contact";
import GoogleIframe from "../google-iframe";
import Footer from "../global/footer";
import Header from "../global/header";
import Intro from "../intro";
import APropos from "../a-propos";
import Pricing from "../pricing";
import Atelier from "../atelier";

const MainPage = ({header, footer}) => {
    const {menuv2} = footer;

    return (
        <>
            <Header/>
            <Intro isBg="yes"/>
            <LeCabinet isBg=""/>
            <APropos isBg="yes"/>

            <PourQui isBg=""/>
            <Pricing isBg="yes"/>
            <Atelier isBg="yes"/>
            <Contact isBg=""/>
            <GoogleIframe/>
            <Footer isBg="yes" menu={menuv2}/>
        </>
    );
};

export default MainPage;


import React from "react";

import Header from "../../global/header";
import Footer from "../../global/footer";
import Breadcrumbs from "../../breadcrumbs";

const Blog003 = ({ headerv3, footer}) => {
  const { menuv2 } = footer;

  return (
    <>
      <Header />
      <Breadcrumbs isBg="yes" title="L'ostéopathie pour les nourrissons" />
      <div id="single-blog" className="section-padding single-blog">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <article>
                <h2 className="display-5 single-blog__title">
                  En cours d'écriture...
                </h2>
              </article>
            </div>
          </div>
        </div>
      </div>
      <Footer isBg="yes" menu={menuv2} />
    </>
  );
};

export default Blog003;

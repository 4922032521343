
import React from "react";

import Header from "../../global/header";
import Footer from "../../global/footer";
import Breadcrumbs from "../../breadcrumbs";

const Blog001 = ({ headerv3, footer}) => {
  const { menuv2 } = footer;

  return (
    <>
      <Header />
      <Breadcrumbs isBg="yes" title="L'ostéopathie pour la femme enceinte" />
      <div id="single-blog" className="section-padding single-blog">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <article>
                <h2 className="display-5 single-blog__title">
                  Introduction à l'ostéopathie chez la femme enceinte
                </h2>
                <div className="single-blog__para">
                  <p>
                    L'ostéopathie est une approche thérapeutique qui utilise des <b>techniques manuelles</b> pour améliorer la santé et la mobilité des différentes parties du corps humain.
                  </p>
                  <p>
                    Cette méthode est souvent utilisée pour soulager les douleurs musculo-squelettiques, les maux de tête, les problèmes de digestion, les troubles du sommeil, et bien d'autres affections.
                  </p>

                  <div className="row mt-4">
                    <div className="col-md-5 mb-4">
                      <div className="post-img">
                        <img
                          className="img-fluid"
                          src="/assets/images/blog/blog_01.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-7">
                      <p>
                    <b>La grossesse est une période de grands changements physiques</b> pour les femmes, qui peuvent causer des douleurs et des inconforts.
                      </p>
                        <p>
                    L'ostéopathie peut aider à soulager ces symptômes en travaillant sur les tissus mous et les articulations pour améliorer la mobilité et la circulation sanguine.
                        </p>

                    </div>
                  </div>
                    <p>
                      De plus, l'ostéopathie peut aider à <b>préparer le corps de la femme enceinte pour l'accouchement</b> en améliorant la fonction pelvienne et en préparant les tissus pour un accouchement plus facile Le but premier étant de donner toute sa capacité de mobilité au bassin pour facilité de passage du bébé.
                    </p>


                  <h2 className="display-6 single-blog__title">
                    Les bienfaits de l'ostéopathie chez la femme enceinte
                  </h2>
                  <p>
                    Il y a de nombreux bienfaits à l'ostéopathie chez les femmes enceintes. Voici quelques exemples :
                  </p>

                  <ul>
                    <li>
                      <strong>Réduction des douleurs musculo-squelettiques</strong>
                      <p>
                          Les femmes enceintes peuvent ressentir des <b>douleurs dans le dos</b>,souffrir de la fameuse “sciatique de la femme enceinte”, avoir des douleurs pelviennes, <b>tiraillements du bas du ventre</b>, sensation de difficulté à la respiration, maux de tête, douleurs aux hanche et dans les jambes.
L'ostéopathie peut aider à soulager ces douleurs en travaillant sur les muscles, ligaments, fascias et articulations pour améliorer la mobilité et réduire les tensions.</p>
                    </li>
                    <li>
                      <strong>Amélioration de la digestion</strong>
                      <p>
                          La grossesse peut également causer des problèmes de digestion, tels que la constipation et les brûlures d'estomac. L'ostéopathie aide à améliorer ces désagréments,et permet une meilleure circulation dans l'abdomen pour <b>favoriser une meilleure digestion</b>.
                      </p>
                    </li>
                    <li>
                      <strong>Amélioration du sommeil </strong>
                      <p>
                          Les femmes enceintes peuvent avoir des problèmes de sommeil en raison de l'inconfort et des douleurs. Comme dis précédemment, l’ostéopathe soulage ces symptômes pour favoriser un <b>sommeil plus réparateur</b> (des plus important avant d'accueillir un enfant).
                      </p>
                    </li>
                    <li>
                    <strong>Préparation à l'accouchement</strong>
                    <p>
                    L’accouchement est un <b>processus naturel magnifique</b> et qui demande une adaptation particulière du corps de la femme. Afin de l’y préparer, l’ostéopathe libère, tout le long de la grossesse, toute la sphère pelvienne ainsi que les muscles et zones nécessaires à la poussée.
                        Les séances de préparation à l’accouchement sont du <b>cas par cas</b>, et nécessite une attention particulière aux ressenties des patientes.
Des conseils vous seront procurés à chaque fin de séance pour vous permettre de vous relaxer, et de continuer les exercices à la maison sereinement.
                    </p>
                    </li>
                  </ul>

                  <p>
                    En résumé, l'ostéopathie est une approche thérapeutique très bénéfique pour les femmes enceintes. Il est important dès le premier trimestre de grossesse de consulter l’ostéopathe.
                    </p>
                  <p>
                    Au plaisir de vous retrouver en consultation pour vous accompagner tout le long de votre grossesse.
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
      <Footer isBg="yes" menu={menuv2} />
    </>
  );
};

export default Blog001;
